import React, { Component } from 'react';
import 'react-image-lightbox/style.css';
import ReactPlayer from 'react-player';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './TabThree.scss';



// import bigPortfolio1 from "../../assets/images/portfolio/big/dp-big--portfolio-01.jpg";
// import bigPortfolio2 from "../../assets/images/portfolio/big/dp-big--portfolio-02.jpg";
// import bigPortfolio3 from "../../assets/images/portfolio/big/dp-big--portfolio-03.jpg";
// import bigPortfolio4 from "../../assets/images/portfolio/big/dp-big--portfolio-04.jpg";
// import bigPortfolio5 from "../../assets/images/portfolio/big/dp-big--portfolio-05.jpg";
// import bigPortfolio6 from "../../assets/images/portfolio/big/dp-big--portfolio-06.jpg";
// import bigPortfolio7 from "../../assets/images/portfolio/big/dp-big--portfolio-07.jpg";
// import bigPortfolio8 from "../../assets/images/portfolio/big/dp-big--portfolio-08.jpg";
// import bigPortfolio9 from "../../assets/images/portfolio/big/dp-big--portfolio-09.jpg";
// import bigPortfolio10 from "../../assets/images/portfolio/big/dp-big--portfolio-10.jpg";
// import bigPortfolio11 from "../../assets/images/portfolio/big/dp-big--portfolio-11.jpg";



//Foam Roller Videos
import FREspalda from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Espalda.mp4";
import FRFasciaLataDeCara from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Fascia_lata_de_cara.mp4";
import FRFasciaLataDeEspaldas from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Fascia_lata_de_espaldas.mp4";
import FRGemelos from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Gemelos.mp4";
import FRGluteo from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Gluteo.mp4";
import FRIsquiotibiales from "../../assets/images/portfolio/pack7En1/foamRoller/FR-Isquiotibiales.mp4";

//Palo de masaje Videos
import SMAbductor from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Abductor.mp4";
import SMcuadriceps from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Cuadriceps.mp4";
import SMCuadricepsCompleto from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Cuadriceps_completo.mp4";
import SMGemelos from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Gemelos.mp4";
import SMIsquiotibiales from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Isquiotibiales.mp4";
import SMTensorFasciaLata from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Tensor_fascia_lata.mp4";
import SMTibialAnterior from "../../assets/images/portfolio/pack7En1/stickMasaje/SM-Tibial_anterior.mp4";

//Bola con pinchos Videos
import PPBiceps from "../../assets/images/portfolio/pack7En1/pelotaPinchos/PP-Biceps.mp4";
import PPPie from "../../assets/images/portfolio/pack7En1/pelotaPinchos/PP-Pie.mp4";
import PPTrapecio from "../../assets/images/portfolio/pack7En1/pelotaPinchos/PP-Trapecio.mp4";
import PPTriceps from "../../assets/images/portfolio/pack7En1/pelotaPinchos/PP-Triceps.mp4";

//Banda de resistencia Videos
import BEAperturasFrontales from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Aperturas_frontales.mp4";
import BEAperturasLaterales from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Aperturas_laterales.mp4";
import BEAperturasTraseras from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Aperturas_traseras.mp4";
import BEGluteo from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Gluteo.mp4";
import BEPuente from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Puente.mp4";
import BESentadillas from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Sentadillas.mp4";
import BETobillos from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Tobillos.mp4";
import BEZancadasLaterales from "../../assets/images/portfolio/pack7En1/bandaElastica/BE-Zancadas_laterales.mp4";

//Banda en forma de 8 Videos
import GE8Biceps from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Biceps.mp4";
import GE8Hombro from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Hombro.mp4";
import GE8Pecho from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Pecho.mp4";
import GE8Remo from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Remo.mp4";
import GE8RemoDePie from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Remo_de_pie.mp4";
import GE8TricepsDePie from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Triceps_de_pie.mp4";
import GE8TricepsLateral from "../../assets/images/portfolio/pack7En1/bandaEn8/GE8-Triceps_lateral.mp4";






const categories = {
  foamRoller: 'Foam roller',
  massageStick: 'Palo de masaje',
  spikyBall: 'Bola de masaje',
  resistanceBand: 'Banda de resistencia',
  eightShapedBand: 'Goma en forma de 8',
}

//All
// const TabOne = [
//   {
//     image: '01',
//     bigImage: bigPortfolio1,
//     category: categories.foamRoller,
//     title: 'Ejercicio para la espalda'
//   },
//   {
//     image: '02',
//     bigImage: bigPortfolio2,
//     category: categories.massageStick,
//     title: 'Ejercicio para las piernas'
//   },
//   {
//     image: '03',
//     bigImage: bigPortfolio3,
//     category: categories.spikyBall,
//     title: 'Ejercicio para los pies'
//   },
//   {
//     image: '04',
//     bigImage: bigPortfolio4,
//     category: categories.resistanceBand,
//     title: 'Ejercicio para los brazos'
//   },
//   {
//     image: '05',
//     bigImage: bigPortfolio5,
//     category: categories.spikyBall,
//     title: 'Ejercicio para las piernas'
//   },
//   {
//     image: '06',
//     bigImage: bigPortfolio6,
//     category: categories.eightShapedBand,
//     title: 'Ejercicio para los brazos'
//   },
//   {
//     image: '07',
//     bigImage: bigPortfolio7,
//     category: categories.foamRoller,
//     title: 'Ejercicio para las piernas'
//   },
//   {
//     image: '08',
//     bigImage: bigPortfolio8,
//     category: categories.resistanceBand,
//     title: 'Ejercicio para las piernas'
//   },
//   {
//     image: '09',
//     bigImage: bigPortfolio9,
//     category: categories.spikyBall,
//     title: 'Ejercicio para el cuello'
//   },

// ]

//Foam Roller
const TabTwo = [
  {
    image: 'FR-01',
    bigImage: FRGemelos,
    category: categories.foamRoller,
    title: 'Gemelos'
  },
  {
    image: 'FR-02',
    bigImage: FRIsquiotibiales,
    category: categories.foamRoller,
    title: 'Isquiotibiales'
  },
  {
    image: 'FR-03',
    bigImage: FRFasciaLataDeCara,
    category: categories.foamRoller,
    title: 'Tensor de la fascia lata (de cara)'
  },
  {
    image: 'FR-04',
    bigImage: FRFasciaLataDeEspaldas,
    category: categories.foamRoller,
    title: 'Tensor de la fascia lata (de espaldas)'
  },
  {
    image: 'FR-05',
    bigImage: FRGluteo,
    category: categories.foamRoller,
    title: 'Glúteos'
  },
  {
    image: 'FR-06',
    bigImage: FREspalda,
    category: categories.foamRoller,
    title: 'Espalda'
  },
]

//Massage Stick
const TabThree = [
  {
    image: 'SM-01',
    bigImage: SMGemelos,
    category: categories.massageStick,
    title: 'Gemelos'
  },
  {
    image: 'SM-02',
    bigImage: SMTibialAnterior,
    category: categories.massageStick,
    title: 'Tibial anterior'
  },
  {
    image: 'SM-03',
    bigImage: SMTensorFasciaLata,
    category: categories.massageStick,
    title: 'Tensor de la fascia lata'
  },
  {
    image: 'SM-04',
    bigImage: SMIsquiotibiales,
    category: categories.massageStick,
    title: 'Isquiotibiales'
  },
  {
    image: 'SM-05',
    bigImage: SMcuadriceps,
    category: categories.massageStick,
    title: 'Cuádriceps'
  },
  {
    image: 'SM-06',
    bigImage: SMCuadricepsCompleto,
    category: categories.massageStick,
    title: 'Cuádriceps completo'
  },
  {
    image: 'SM-07',
    bigImage: SMAbductor,
    category: categories.massageStick,
    title: 'Abductor'
  },
]

//Spiky Ball
const TabFour = [
  {
    image: 'PP-01',
    bigImage: PPPie,
    category: categories.spikyBall,
    title: 'Pies'
  },
  {
    image: 'PP-02',
    bigImage: PPBiceps,
    category: categories.spikyBall,
    title: 'Bíceps'
  },
  {
    image: 'PP-03',
    bigImage: PPTriceps,
    category: categories.spikyBall,
    title: 'Tríceps'
  },
  {
    image: 'PP-04',
    bigImage: PPTrapecio,
    category: categories.spikyBall,
    title: 'Trapecios'
  },

]

//Resistance Band
const TabFive = [
  {
    image: 'BE-01',
    bigImage: BETobillos,
    category: categories.resistanceBand,
    title: 'Tobillos'
  },
  {
    image: 'BE-02',
    bigImage: BEZancadasLaterales,
    category: categories.resistanceBand,
    title: 'Zancadas laterales'
  },
  {
    image: 'BE-03',
    bigImage: BESentadillas,
    category: categories.resistanceBand,
    title: 'Sentadillas'
  },
  {
    image: 'BE-04',
    bigImage: BEAperturasLaterales,
    category: categories.resistanceBand,
    title: 'Aperturas laterales'
  },
  {
    image: 'BE-05',
    bigImage: BEAperturasFrontales,
    category: categories.resistanceBand,
    title: 'Aperturas frontales'
  },
  {
    image: 'BE-06',
    bigImage: BEAperturasTraseras,
    category: categories.resistanceBand,
    title: 'Aperturas traseras'
  }, {
    image: 'BE-07',
    bigImage: BEGluteo,
    category: categories.resistanceBand,
    title: 'Glúteos'
  },
  {
    image: 'BE-08',
    bigImage: BEPuente,
    category: categories.resistanceBand,
    title: 'Puente'
  },
]

//Eight Shaped Band
const TabSix = [
  {
    image: 'GE8-01',
    bigImage: GE8Biceps,
    category: categories.eightShapedBand,
    title: 'Bíceps'
  },
  {
    image: 'GE8-02',
    bigImage: GE8TricepsDePie,
    category: categories.eightShapedBand,
    title: 'Tríceps de pie'
  },
  {
    image: 'GE8-03',
    bigImage: GE8TricepsLateral,
    category: categories.eightShapedBand,
    title: 'Tríceps lateral'
  },
  {
    image: 'GE8-04',
    bigImage: GE8Hombro,
    category: categories.eightShapedBand,
    title: 'Hombros'
  },
  {
    image: 'GE8-05',
    bigImage: GE8Pecho,
    category: categories.eightShapedBand,
    title: 'Pecho'
  },
  {
    image: 'GE8-06',
    bigImage: GE8RemoDePie,
    category: categories.eightShapedBand,
    title: 'Remo de pie'
  },
  {
    image: 'GE8-07',
    bigImage: GE8Remo,
    category: categories.eightShapedBand,
    title: 'Remo'
  },
]

class TabStyleThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      tab2: 0,
      tab3: 0,
      tab4: 0,
      tab5: 0,
      tab6: 0,
      isOpen: false,
    };
  }



  render() {
    const { column } = this.props;
    const { tab1, tab2, tab3, tab4, tab5, tab6, isOpen } = this.state;
    return (
      <div>
        <Tabs>
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="tablist-inner">
                <TabList className="pv-tab-button text-center mt--0 ">
                  {/* <Tab >
                    <span style={{ fontSize: "12px" }}>Todos</span>
                  </Tab> */}
                  <Tab>
                    <span>{categories.foamRoller}</span>
                  </Tab>
                  <Tab>
                    <span>{categories.massageStick}</span>
                  </Tab>
                  <Tab>
                    <span>{categories.spikyBall}</span>
                  </Tab>
                  <Tab>
                    <span>{categories.resistanceBand}</span>
                  </Tab>
                  <Tab>
                    <span>{categories.eightShapedBand}</span>
                  </Tab>
                </TabList>
              </div>
            </div>
          </div>

          {/* <TabPanel className="row row--35">
            {TabOne.map((value, index) => (
              <div className={`${column}`} key={index}>
                {isOpen && (
                  <Lightbox
                    mainSrc={TabOne[tab1].bigImage}
                    nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                    prevSrc={
                      TabOne[(tab1 + TabOne.length - 1) % TabOne.length]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        tab1: (tab1 + 1) % TabOne.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )}
                <div className="item-portfolio-static">
                  <div
                    onClick={() =>
                      this.setState({ isOpen: true, tab1: index })
                    }
                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <div className="thumbnail">
                          <a href="#portfolio-details">

                            <img
                              src={value.bigImage}
                              alt="Portfolio Images"
                            />
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4>
                            {value.title}
                          
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel> */}

          <TabPanel className="row row--35">
            {TabTwo.map((value, index) => (
              <div className={`${column}`} key={index}>

                <div className="item-portfolio-static">
                  <div

                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">

                        <ReactPlayer style={{ borderRadius: "20px" }} url={value.bigImage} controls width={"100%"} height={"100%"} config={{
                          file: {
                            attributes: {
                              playsInline: true,
                              webkitPlaysinline: true,
                              preload: 'metadata',
                            },
                          },
                        }} />
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4 className='exercise-title'>
                            {value.title}

                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          {/* Los comentarios de aqui es lo que estaba en cada tabpanel, se ha dejado como referencia. */}
          <TabPanel className="row row--35">
            {TabThree.map((value, index) => (
              <div className={`${column}`} key={index}>
                {/* {isOpen && (
                  <Lightbox
                    mainSrc={TabThree[tab3].bigImage}
                    nextSrc={TabThree[(tab3 + 1) % TabThree.length]}
                    prevSrc={
                      TabThree[
                      (tab3 + TabThree.length - 1) % TabThree.length
                      ]
                    }
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        tab3:
                          (tab3 + TabThree.length - 1) % TabThree.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        tab3: (tab3 + 1) % TabThree.length,
                      })
                    }
                    imageLoadErrorMessage="Image Loading ..."
                    enableZoom={false}
                  />
                )} */}
                <div className="item-portfolio-static">
                  <div
                  // onClick={() =>
                  //   this.setState({ isOpen: true, tab3: index })
                  // }
                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">


                        <ReactPlayer style={{ borderRadius: "20px" }} url={value.bigImage} controls width={"100%"} height={"100%"} config={{
                          file: {
                            attributes: {
                              playsInline: true,
                              webkitPlaysinline: true,
                              preload: 'metadata',
                            },
                          },
                        }} />

                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <p>{value.category}</p>
                        <h4>
                          {value.title}
                          {/* <a className='exercise-title' href="#portfolio-details"></a> */}
                        </h4>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          <TabPanel className="row row--35">
            {TabFour.map((value, index) => (
              <div className={`${column}`} key={index}>

                <div className="item-portfolio-static">
                  <div

                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <ReactPlayer style={{ borderRadius: "20px" }} url={value.bigImage} controls width={"100%"} height={"100%"} config={{
                          file: {
                            attributes: {
                              playsInline: true,
                              webkitPlaysinline: true,
                              preload: 'metadata',
                            },
                          },
                        }} />
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4>
                            {value.title}

                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>

          <TabPanel className="row row--35">
            {TabFive.map((value, index) => (
              <div className={`${column}`} key={index}>

                <div className="item-portfolio-static">
                  <div

                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <ReactPlayer style={{ borderRadius: "20px" }} url={value.bigImage} controls width={"100%"} height={"100%"} config={{
                          file: {
                            attributes: {
                              playsInline: true,
                              webkitPlaysinline: true,
                              preload: 'metadata',
                            },
                          },
                        }} />
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4>
                            {value.title}

                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
          <TabPanel className="row row--35">
            {TabSix.map((value, index) => (
              <div className={`${column}`} key={index}>

                <div className="item-portfolio-static">
                  <div

                  >
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <ReactPlayer style={{ borderRadius: "20px" }} url={value.bigImage} controls width={"100%"} height={"100%"} config={{
                          file: {
                            attributes: {
                              playsInline: true,
                              webkitPlaysinline: true,
                              preload: 'metadata',
                            },
                          },
                        }} />
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p>{value.category}</p>
                          <h4>
                            {value.title}

                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}


export default TabStyleThree
