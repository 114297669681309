// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA-GK-1rTlaRc07PUbLBNO9k_3XE_9xiao",
    authDomain: "finyt-backend.firebaseapp.com",
    projectId: "finyt-backend",
    storageBucket: "finyt-backend.appspot.com",
    messagingSenderId: "1091759798461",
    appId: "1:1091759798461:web:1beae755e3b8a0cb9b5fd4",
    measurementId: "G-LQ87BKXEYH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);