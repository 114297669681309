import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import TabThree from "../elements/tab/TabThree";

import { videoTagString, VideoTag } from "react-video-tag";

import designerAvatar from "../assets/images/about/designer-avatar.png";
import { Helmet } from "react-helmet";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>


        <Helmet pageTitle="FINYT">
          <meta charSet="utf-8" />
          <title>FINYT</title>
          <link rel="canonical" href="https://finyt.es/" />
          <meta name="robots" content="index, follow" />
          <meta name="description" content="FINYT. Esta vez sí." />


        </Helmet>
        {/* Start Header Area  */}
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-white"

        />
        {/* End Header Area  */}

        {/* <div className="btn-hire">
          <a style={{ backgroundColor: "#ff2e43", fontSize: "12px", color: "white" }} className="btn-default" href="/contact">
            ¿Alguna duda?
          </a>
        </div> */}

        <div
          style={{ flex: 1 }}
          // style={{ height: "100vh", display: "flex", flexDirection: "column" }}
          className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              // style={{ flex: 1, height: "90vh", display: "flex", flexDirection: "column" }}

              className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--6 rn-slider-height"
              key={index}
            >
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    {/* Comentado esto mientras se construye el diseño */}
                    {/* <div className="designer-thumbnail">
                      <img src={designerAvatar} alt="Slider Images" />
                    </div> */}
                  </div>
                  <div className="col-lg-12 pt--40">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span className="text-white">{value.category}</span> : ""}
                      <h1 className="title text-white">
                        Hola, esto es FINYT.
                        {/* Proximamente, FINYT. */}
                        <br />
                        <TextLoop >
                          {/* <span> Sin excusas.</span>
                          <span> Sin rodeos.</span>
                          <span> A lo que importa.</span> */}
                          <span > Esta vez sí.</span>
                        </TextLoop>{" "}
                      </h1>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {/* Comentado esto mientras se construye el diseño */}
                      {/* <div className="d-flex align-items-center mt--10">
                        <ModalVideo
                          channel="youtube"
                          isOpen={this.state.isOpen}
                          videoId="ZOoVOfieAF8"
                          onClose={() => this.setState({ isOpen: false })}
                        />
                        <button
                          className="video-popup theme-color"
                          onClick={this.openModal}
                        >
                          <span className="play-icon"></span>
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
        {/* <div style={{ height: "10px", width: "100%", backgroundColor: "black", paddingBottom: "100px" }}>
          <div style={{
            height: "2px", width: "80vw", margin: "auto",

            backgroundColor: "black"
          }}>

          </div>
        </div>
        <h1 className="titulo" style={{ textAlign: "center", color: "white", backgroundColor: "black", margin: "0px" }}>Guía de ejercicios</h1>

        <div className="designer-portfolio-area pt--60 pb--120  bg_color--6">
          <div className="wrapper plr--70 plr_sm--30 plr_md--30">
            <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>
        </div>
        End Designer Portfolio */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </React.Fragment>
    );
  }
}

export default Home;
