import React from 'react';
import { signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth, db } from '../firebase.js';
import { useState } from 'react';
import googleIcon from '../assets/images/icons/icono-google.svg';
import { Helmet } from 'react-helmet';
import { doc, setDoc } from 'firebase/firestore';
import Header from '../component/header/Header.jsx';
import Select from 'react-select';
import iLogo from '../assets/images/logo/logo-finyt-blanco.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.js';


function Register() {

    const history = useHistory()

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [isSignUp, setIsSignUp] = useState(false);

    const [resetPassword, setResetPassword] = useState(false);

    const ageRanges = [
        { value: '0-20', label: 'De 0 a 20' },
        { value: '21-30', label: 'De 21 a 30' },
        { value: '31-40', label: 'De 31 a 40' },
        { value: '41-50', label: 'De 41 a 50' },
        { value: '51-60', label: 'De 51 a 60' },
        { value: '+60', label: 'Más de 60' },
    ]

    const selectInputStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '40px',  // Ajusta la altura mínima
            height: '40px',     // Ajusta la altura total
            marginBottom: '12px', // Ajusta el margen inferior
            borderRadius: '8px', // Ajusta el radio de borde




        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',      // Elimina márgenes adicionales
            padding: '0px',     // Elimina padding adicional
            fontSize: '14px',   // Ajusta el tamaño de fuente
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0px 8px', // Ajusta el padding del contenedor de valores
            fontSize: '14px',   // Ajusta el tamaño de la fuente del contenedor de valores
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '14px',   // Ajusta el tamaño de la fuente del valor seleccionado
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',   // Ajusta el tamaño de la fuente del placeholder
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#ff2e43' : state.isFocused ? '#ff8e9a' : state.isActive ? '#007bff' : '#fff', // Color de fondo
            color: state.isSelected ? '#fff' : '#000', // Color del texto
            fontSize: '14px', // Tamaño de la fuente de las opciones
            padding: '10px 12px', // Padding de las opciones
            cursor: 'pointer', // Cambia el cursor al pasar sobre la opción
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: '0px', // Margen superior del menú
        }),
    };

    const handleEmailPasswordAuth = async (e) => {
        e.preventDefault();
        try {
            let userCredential;
            if (isSignUp) {
                userCredential = await createUserWithEmailAndPassword(auth, email, password);
            } else {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            }
            const user = userCredential.user;
            if (isSignUp) {
                await updateUserProfile(user, { age, orderNumber });
            }
            console.log(isSignUp ? "Usuario registrado" : "Inicio de sesión exitoso");
        } catch (error) {
            console.error("Error en autenticación:", error.message);
        }
    };

    const updateUserProfile = async (user) => {
        try {
            await setDoc(doc(db, "users", user.uid), {
                email: user.email,
                firstName,
                lastName,
                age,
                orderNumber,
                createdAt: new Date()
            });
            console.log("Perfil de usuario creado/actualizado en Firestore");
        } catch (error) {
            console.error("Error al guardar el perfil en Firestore:", error);
        }
    };

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            await updateUserProfile(result.user, { age, orderNumber });
            console.log("Inicio de sesión con Google exitoso");
        } catch (error) {
            console.error("Error en inicio de sesión con Google:", error.message);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            console.log("Correo de restablecimiento de contraseña enviado a ", email);
            setResetPassword(false);
        } catch (error) {
            console.error("Error al enviar correo de restablecimiento:", error.message);
        }
    };

    if (resetPassword) {
        return (
            <div className='login-page'>
                <form onSubmit={handleResetPassword}>
                    <input
                        className='login-input'
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Correo electrónico"
                        required
                    />
                    <button className='btn-login' type="submit">Enviar correo de restablecimiento</button>
                    <button className='btn-register' onClick={() => setResetPassword(false)}>Volver</button>
                </form>
            </div>

        );
    }

    const step1 = () => {
        <div>
            <h1>Número de pedido</h1>

            <input
                className='login-input'
                type="text"
                value={orderNumber}
                onChange={(e) => setOrderNumber(e.target.value)}
                placeholder="Nº de envío"
            />
        </div>
    }

    return (
        <div className='login-page'>
            <Helmet pageTitle="FINYT - Regístrate" >
                <title>FINYT - Regístrate</title>
                <meta name="description" content="Login" />
            </Helmet>

            {/* Start Header Area  */}
            {/* <Header
                headerPosition="header--static"
                logo="symbol-dark"
                color="color-white"

            /> */}
            <div className='login-items-section'>
                <div className='login-items-wrapper'>
                    <form className='login-form' onSubmit={handleEmailPasswordAuth}>
                        <img onClick={() => history.push('/')} style={{ cursor: 'pointer' }} className='login-logo' src={iLogo} alt="" />
                        <input
                            className='login-input'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Correo electrónico"
                            required
                        />
                        <input
                            className='login-input'
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Contraseña"
                            required
                        />


                        <input
                            className='login-input'
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="Nombre"
                            required

                        />
                        {/* <input
                                    className='login-input'
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Apellidos"
                                    required
                                /> */}

                        <Select isSearchable={false} options={ageRanges} name={age}
                            classNamePrefix="select-input"
                            styles={
                                selectInputStyles
                            }
                            placeholder="Selecciona un rango de edad..."
                        />
                        {/* Esto se pondra en otro sitio */}
                        {/* <input
                                    className='login-input'
                                    type="text"
                                    value={orderNumber}
                                    onChange={(e) => setOrderNumber(e.target.value)}
                                    placeholder="Nº de envío"
                                /> */}





                        <button className='btn-register' type="submit">
                            Registrarme
                        </button>
                        <div className='login-divider'></div>
                        <button className='btn-go-to-login' type='button' onClick={() => history.push('/login')}>
                            ¿Ya tienes cuenta? Inicia sesión
                        </button>

                    </form>

                </div>
            </div>
        </div>
    );
}

export default Register;