import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";

import { videoTagString, VideoTag } from "react-video-tag";

import designerAvatar from "../assets/images/about/designer-avatar.png";

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

class CookiesPolicy extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column", backgroundColor: "black" }}>
        <Helmet pageTitle="FINYT - Política de Cookies" description="Política de cookies para realizar con tu pack 7 en 1." noindex nofollow>


        </Helmet>

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static"
          logo="symbol-dark"
          color="color-white"

        />
        {/* End Header Area  */}




        {/* <div style={{ height: "10px", width: "100%", backgroundColor: "black", paddingBottom: "100px" }}>
          <div style={{
            height: "2px", width: "80vw", margin: "auto",
            backgroundColor: "black"
          }}>

          </div>
        </div>
        <div style={{ height: "46vh", width: "100%", backgroundColor: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", paddingTop: "80px" }}>
          <h1 className="titulos-principales" style={{ backgroundColor: "black", color: "white", textAlign: "center", margin: "0px", fontSize: "72px" }}>Bienvenid@ a FINYT</h1>
          <TextLoop>
            <p className="ejercicios-text-loop-p" style={{ textAlign: "center", color: "var(--color-primary)", backgroundColor: "black", margin: "0px", fontSize: "44px", fontWeight: "600" }}>Se acabaron las excusas.</p>
            <p className="ejercicios-text-loop-p" style={{ textAlign: "center", color: "var(--color-primary)", backgroundColor: "black", margin: "0px", fontSize: "44px", fontWeight: "600" }}>Al lío.</p>
          </TextLoop>

        </div> */}
        <div style={{ height: "100%", width: "100%", backgroundColor: "black", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center", paddingTop: "200px" }}>


          {/* <div style={{ displayflex: "flex", justifyContent: "center", alignItems: "center", height: "1px", width: "100vw", backgroundColor: "black", paddingBlock: "80px" }}>
            <div style={{ display: "flex", height: "2.5px", width: "92vw", margin: "auto", backgroundColor: "white" }}></div>
          </div> */}


          <div style={{ background: "black", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100vw" }}>
            <h2 className="titulo-guia-ejercicios" style={{ textAlign: "center", fontWeight: "600", color: "white", backgroundColor: "black", margin: "0px", fontSize: "56px", paddingBottom: "32px" }}>Política de Cookies</h2>

            <div className="descripcion-guia-ejercicios-wrapper" style={{ textAlign: "left", color: "white", backgroundColor: "black", margin: "0px", fontSize: "18px", }}>



              <p>Este sitio web utiliza cookies para mejorar la experiencia del usuario y para obtener información sobre el uso del sitio. En particular, utilizamos cookies de Google Analytics para ayudarnos a analizar cómo los usuarios utilizan nuestro sitio web. En esta política, explicamos qué son las cookies, cómo las usamos y cómo puedes gestionarlas.</p>
              <br />
              <h2 style={{ color: "white" }}>
                ¿Qué son las cookies?
              </h2>

              <p>Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo cuando visitas un sitio web. Estas cookies permiten al sitio web recordar tus acciones y preferencias (como inicio de sesión, idioma, tamaño de fuente y otras preferencias de visualización) durante un período de tiempo, para que no tengas que volver a configurarlas cada vez que vuelvas al sitio o navegues de una página a otra.</p>

              <br />
              <h2 style={{ color: "white" }}>
                ¿Cómo usamos las cookies?

              </h2>

              <p>En nuestro sitio web, utilizamos cookies para diversas finalidades, incluyendo:</p>
              <p>- <strong>Cookies esenciales:</strong> Estas cookies son necesarias para el funcionamiento del sitio web y no se pueden desactivar en nuestros sistemas. Por lo general, se configuran solo en respuesta a acciones que has realizado y que constituyen una solicitud de servicios, como configurar tus preferencias de privacidad, iniciar sesión o completar formularios.</p>
              <p>- <strong>Cookies de rendimiento:</strong> Utilizamos cookies de Google Analytics para recopilar información sobre cómo los visitantes utilizan nuestro sitio web. Esta información nos ayuda a entender cómo los usuarios interactúan con el sitio, permitiéndonos mejorar la funcionalidad y el contenido del mismo.</p>

              <br />
              <h2 style={{ color: "white" }}>
                Cookies de Google Analytics
              </h2>

              <p>
                Google Analytics utiliza cookies para ayudarnos a analizar cómo los visitantes utilizan nuestro sitio web. La información generada por la cookie sobre tu uso del sitio web (incluida tu dirección IP) será transmitida y almacenada por Google en servidores en Estados Unidos. Google utilizará esta información con el propósito de evaluar el uso del sitio web, recopilando informes sobre la actividad del sitio para los operadores del mismo y proporcionando otros servicios relacionados con la actividad del sitio web y el uso de Internet.
              </p>

              <p>
                Las principales cookies de Google Analytics que utilizamos son:
              </p>

              <p>
                <strong>· _ga:</strong> Esta cookie se utiliza para distinguir a los usuarios. Se almacena durante un período de 2 años.
              </p>

              <p>
                <strong>· _gid:</strong> Esta cookie se utiliza para distinguir a los usuarios y tiene una duración de 24 horas.
              </p>

              <p>
                <strong>· _gat:</strong> Esta cookie se utiliza para limitar el porcentaje de solicitudes. Se almacena durante 1 minuto.
              </p>

              <p><strong>· ga[ID de usuario]:</strong>
                Esta cookie contiene el ID de usuario específico de Google Analytics. Se utiliza para realizar un seguimiento de las acciones del usuario en el sitio web y tiene una duración de 2 años.
              </p>

              <br />
              <h2 style={{ color: "white" }}>
                Gestionar las cookies
              </h2>

              <p>
                Puedes gestionar el uso de cookies a través de la configuración de tu navegador. La mayoría de los navegadores te permiten:
              </p>

              <p>
                <strong>· </strong>Ver qué cookies tienes y eliminarlas de forma individual.
              </p>

              <p>
                <strong>· </strong>Bloquear cookies de terceros.
              </p>

              <p>
                <strong>· </strong>Bloquear cookies de sitios específicos.
              </p>

              <p>
                <strong>· </strong>Bloquear todas las cookies.
              </p>

              <p>
                <strong>· </strong>Eliminar todas las cookies cuando cierres tu navegador.
              </p>


              <p>Ten en cuenta que si eliges bloquear todas las cookies, nuestro sitio web puede no funcionar correctamente y podrías no tener acceso a ciertas áreas o servicios.</p>


              <br />
              <h2 style={{ color: "white" }}>
                Cambios en nuestra Política de Cookies
              </h2>
              <p>Podemos actualizar esta Política de Cookies de vez en cuando para reflejar, por ejemplo, cambios en las cookies que utilizamos o por otros motivos operativos, legales o regulatorios. Por favor, vuelve a visitar esta Política de Cookies regularmente para estar informado sobre nuestro uso de cookies y tecnologías relacionadas.</p>


              <br />
              <h2 style={{ color: "white" }}>
                Contacto
              </h2>
              <p>Si tienes alguna pregunta sobre el uso de cookies en nuestro sitio web, puedes contactarnos a través de la siguiente dirección de correo electrónico: <a style={{ color: "var(--color-primary)" }} href="mailto:hola@finyt.es">hola@finyt.es</a></p>

              <br />
              <p>Fecha de la última actualización: 24/07/2024.</p>
              <br /><br />

            </div>
          </div>

        </div>


        {/* End Designer Portfolio  */}

        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </div >
    );
  }
}

export default CookiesPolicy;
