import React from "react";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";

import logoLight from "../../assets/images/logo/logo-light.png";
import logoFinyt from "../../assets/images/logo/logo-finyt-blanco.svg";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

const FooterTwo = () => {
  return (
    <div
      // style={{ height: "100%", flex: 1 }}
      className="footer-style-2 ptb--30 bg_color--6">
      <div className="wrapper plr--50 plr_sm--20">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner">
              <div className="logo text-center text-sm-left mb_sm--20">
                <a href="/">
                  <img src={logoFinyt} alt="Logo images" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="inner text-center">
              {/* <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a href={`${val.link}`}>{val.Social}</a>
                  </li>
                ))}
              </ul> */}
              <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="header-btn">
                <a style={{ width: "fit-content", height: "40px", textAlign: "center", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }}
                  className="btn-default btn-border btn-opacity"
                  href="/contacto"
                >
                  <span>Contáctanos</span>
                </a>
              </div>
            </div>

          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
              <div className="text">
                <p>Copyright © 2024 FINYT.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FooterTwo;
