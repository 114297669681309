import React, { Component } from "react";
import Helmet from "../component/common/Helmet";

import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TextLoop from "react-text-loop";

import logoFinyt from "../assets/images/logo/logo-finyt-blanco.svg";

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet pageTitle="FINYT - Contáctanos" description="¿Tienes alguna pregunta o consulta relacionada con nuestros productos deportivos? ¡No dudes en ponerte en contacto con nosotros!" >
          <title>FINYT - Contáctanos</title>

        </Helmet>

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        {/* <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"
          data-black-overlay="6"
        > */}
        <div
          style={{ height: "91.5vh", display: "flex", flexDirection: "column" }}
          className="rn-page-title-area pt--120 pb--190 bg_color--6"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div style={{ display: "flex", justifyContent: "center" }} className="col-lg-12">
                <div className="rn-page-title text-center pt--100 px--20">

                  <div className="titulos-loop-contacto-wrapper">
                    <TextLoop  >
                      <h2 className="title titulos-loop-contacto">Nos encanta mejorar</h2>
                      <h2 className="title titulos-loop-contacto">Necesitamos tu feedback</h2>
                      <h2 className="title titulos-loop-contacto">Tu opinión nos importa, y mucho.</h2>
                    </TextLoop>
                  </div>


                  <p>
                    Si tienes algún problema, duda o sugerencia con tus productos, no dudes en ponerte en contacto con nosotros{" "}
                  </p>
                  <div style={{ margin: "auto", marginTop: "40px" }} className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="rn-address">
                      <div className="icon">
                        <FiMail />
                      </div>
                      <div className="inner">
                        <h4 className="title">Nuestro correo</h4>
                        <p>
                          <a href="mailto:hola@finyt.es">hola@finyt.es</a>
                        </p>
                        {/* <p>
                          <a href="mailto:example@gmail.com">example@gmail.com</a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}



        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        {/* <Footer /> */}
        <div
          // style={{ height: "100%", flex: 1 }}
          className="footer-style-2 ptb--30 bg_color--6">
          <div className="wrapper plr--50 plr_sm--20">
            <div className="row align-items-center justify-content-between">
              {/* <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="inner">
                  <div className="logo text-center text-sm-left mb_sm--20">
                    <a href="/">
                      <img src={logoFinyt} alt="Logo images" />
                    </a>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                {/* <div className="inner text-center">
                  <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                    {SocialShare.map((val, i) => (
                      <li key={i}>
                        <a href={`${val.link}`}>{val.Social}</a>
                      </li>
                    ))}
                  </ul>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="header-btn">
                    <a style={{ width: "fit-content", height: "40px", textAlign: "center", fontSize: "14px", display: "flex", alignItems: "center", justifyContent: "center" }}
                      className="btn-default btn-buy"

                      href="/contact"
                    >
                      <span>Contáctanos</span>
                    </a>
                  </div>
                </div> */}

              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 col-12">
                <div className="inner text-lg-center text-center mt_md--20 mt_sm--20">
                  <div className="text">
                    <p>Copyright © 2024 FINYT.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment >
    );
  }
}
export default Contact;
